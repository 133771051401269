import React from 'react';
// eslint-disable-next-line max-len
import LocationProfilePage from '../pages/LocationProfilePage/LocationProfilePage';
import Page404 from '../pages/Page404/Page404';

export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
}

export enum RouteNames {
  Page404Route = '/page-404',
  LocationProfile = '/',
}

export const publicRoutes: IRoute[] = [
  { path: RouteNames.Page404Route, exact: true, component: Page404 },
];

export const privateRoutes: IRoute[] = [
  {
    path: RouteNames.LocationProfile,
    exact: true,
    component: LocationProfilePage,
  },
  {
    path: RouteNames.Page404Route,
    exact: true,
    component: Page404,
  },
];
