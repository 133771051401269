import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  render(): ReactNode {
    const children = this.props;
    const hasError = this.state;

    if (hasError.hasError) {
      return <p>Oops, Error occurred!</p>;
    }

    return children.children;
  }
}

export default ErrorBoundary;
